.image-box{
    display: block;
    position: relative;
}

#celebrity-pic{
    display: block;
    position: relative;
}

.bounding-box{
    position: absolute;
    box-shadow: 0 0 0 3px #357EDD inset;
    background: transparent;
    content:" "
}

.number{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
}
